.anchorBox{
    height: 100%;
}
.anchorBox .ant-tabs-tab{
    padding: 5px 8px !important;
}
.anchorBox .ant-tabs-tabpane{
    padding-left: 0 !important;
}
.anchorBox .ant-card-body{
    padding: 0 15px 15px 15px;
}
.anchorBox .ant-list-item{
    padding: 5px 16px !important;
}
.anchorBox .ant-card-head{
    color: #1677ff;
    font-weight: 400;
    margin-top: -10px;
    margin-left: -10px;
}